import React from "react";
import { graphql } from "gatsby";
import Card from "components/Card/Card.jsx";
import Table from "components/Table/Table";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
function getLink(link, urlArray, keys) {
  const { yearInUrl, quarter, urls } = urlArray;
  var splitLinks = link.split(`/`);
  splitLinks = splitLinks.slice(0, 6).join(`/`);
  console.log(
    `${splitLinks}/${yearInUrl}/${quarter}/${urls[keys - 1]}.pdf - ${keys - 1}`
  );
  return `${splitLinks}/${yearInUrl}/${quarter}/${urls[keys - 1]}.pdf`;
}

const financeByQuarter = ({ classes, data, pageContext }) => {
  const { urlArray } = pageContext;
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var tableHead = [];
  var tableColumns = [];
  var title = data.allStrapiTables.edges.map((document, k) => {
    return (
      <h4 key={k} className={classes.cardTitle}>
        {console.log(`tabel ${urlArray}`)}
        {urlArray == null
          ? document.node.details
          : `Public Disclosures of ${urlArray.publicDisclosureHeader} - ${urlArray.quarter}`}
      </h4>
    );
  });
  data.allStrapiTables.edges.map(document => {
    return document.node.tablerows.map(rows => {
      if (rows.sequence === 1) {
        return rows.tablecolumns.map(columns => {
          tableHead.push(columns.details);
          return tableHead;
        });
      }
    });
  });
  data.allStrapiTables.edges.map(document => {
    tableColumns = document.node.tablerows.map((rows, keys) => {
      var temp = [];
      if (rows.sequence != 1) {
        temp = rows.tablecolumns.map(columns => {
          return (
            <a
              key={keys}
              href={
                urlArray !== null && columns.url != null
                  ? getLink(columns.url, urlArray, keys)
                  : columns.url
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              {columns.details}
            </a>
          );
        });
      }
      return temp;
    });
  });

  var content = (
    <GridContainer>
      <GridItem
        xs={12}
        sm={10}
        md={10}
        className={classNames(
          classes.mrAuto,
          classes.mlAuto,
          classes.textCenter
        )}
      >
        {title}
        <Card>
          <Table striped tableHead={tableHead} tableData={tableColumns} />
        </Card>
      </GridItem>
    </GridContainer>
  );

  let urlPath = data.strapiTables.title;
  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Finance By Quarter | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + urlPath}
          />
          <meta name="title" content="Finance By Quarter | StarHealth.in" />
          <meta name="twitter:title" content="Finance By Quarter | StarHealth.in" />
          <meta
            name="description"
            content="Star Health Finance By Quarter"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Finance By Quarter | StarHealth.in" />
          <meta property="og:description" content="Star Health Finance By Quarter" />
          <meta property="og:url" content={url + urlPath}/>
          <meta name="Finance By Quarter | StarHealth.in" />
          <meta name="twitter:description" content="Star Health Finance By Quarter" />
          <meta property="twitter:url" content={url + urlPath} />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <div className={classes.team}>
                  <h2 className={classes.title}></h2>
                </div>
              </GridItem>
              <GridItem>{content}</GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
financeByQuarter.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  pageContext: PropTypes.object
};
export default withStyles(customStaticPageStyle)(financeByQuarter);

export const financeByQuarterQuery = graphql`
  query financeByQuarter($table: String!) {
    allStrapiTables(
      filter: { title: { eq: $table } }
      sort: { fields: tablerows___sequence, order: ASC }
    ) {
      edges {
        node {
          title
          details
          tablerows {
            id
            sequence
            tablecolumns {
              id
              details
              url
            }
          }
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "finance_by_quarter" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    strapiTables(title: {eq: $table }) {
      title
    }
  }
`;
